import request from '@/utils/request'

export function queryAllcccNameByList() {
  return request({
    url: '/customer/queryAllcccNameBy',
    method: 'get',
  })
}
export function queryAllccdefine1List() {
    return request({
      url: '/customer/queryAllccdefine1',
      method: 'get',
    })
  }
  export function queryAllccdefine2List() {
    return request({
      url: '/customer/queryAllccdefine2',
      method: 'get',
    })
  }
  export function getCaptchaImage() {
    return request({
      url: '/captchaImage',
      method: 'get',
    })
  }
  export function getSomeList(params) {
    return request({
      url: 'basdistributionofgoods/twoCustomer',
      method: 'get',
      params
    })
  }
  export function getSomeListOther(params) {
    return request({
      url: 'basdistributionofgoods/twoQuantity',
      method: 'get',
      params
    })
  }
  
  export function doExport(data) {
    return request({
      url: 'business_management/soSomainCopy/detailExport',
      
      method: 'post',
      responseType: 'blob',
      data
    })
  }
  export function doExports(data) {
    return request({
      url: 'business_management/soSomainCopy/detailcustomerExport',
      method: 'post',
      responseType: 'blob',
      data
    })
  }

  export function changePwd(data) {
    return request({
      url: '/shoppingMallUser/changePwd',
      method: 'post',
      data
    })
  }